.mkcontainer {
    width: 100px;
}

.beacon {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    -webkit-animation: shine 2s ease-in-out infinite;
    animation: shine 2s ease-in-out infinite;
}

.beacon.pickup {
    background-color: #ffa020;
}

.beacon.dropoff {
    -webkit-animation-delay: 1s;
    background-color: #28df99;
    animation-delay: 1s;
}


@-webkit-keyframes shine {
    0%,
    20% {
        box-shadow: 0px 0px 0px 0px rgba(239, 79, 79, .49);
    }

    100% {
        box-shadow: 0px 0px 0px 50px rgba(0, 0, 0, 0);
    }
}

@keyframes shine {

    0%,
    20% {
        box-shadow: 0px 0px 0px 0px rgba(239, 79, 79, .49);
    }

    100% {
        box-shadow: 0px 0px 0px 50px rgba(0, 0, 0, 0);
    }
}